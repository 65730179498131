// Show current time
function updateTime() {
	const timeElement = document.getElementById('currentTime')
	const now = new Date()
	const hours = String(now.getHours()).padStart(2, '0')
	const minutes = String(now.getMinutes()).padStart(2, '0')
	timeElement.textContent = `${hours}:${minutes}`
}

// Update time every minute
setInterval(updateTime, 60000)
updateTime() // Initial call

// Toggle the side menu and overlay
const burgerMenu = document.getElementById('burgerMenu')
const sideMenu = document.getElementById('sideMenu')
const overlay = document.getElementById('overlay')

burgerMenu.addEventListener('click', () => {
	burgerMenu.classList.toggle('active')
	sideMenu.classList.toggle('active')
	overlay.classList.toggle('hidden')
})

overlay.addEventListener('click', () => {
	burgerMenu.classList.remove('active')
	sideMenu.classList.remove('active')
	overlay.classList.add('hidden')
})
